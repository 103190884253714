import React from "react"
import Styles from "./css/nlp.module.scss"
import Data from "./data/data.json"
import {isIeEdge,isChrome,isFirefox,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"
class Trbm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: Data
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('safari')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(isSafari()){
      const browserData = Object.assign(this.state.data, this.state.data.safari);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    if (typeof window != "undefined") {
        window.mapParams();
    }
    });
  }

  render() {
    return (
      <section className = {Styles.nlp}>
        <div className = {Styles.container}>
          <div className = {Styles.splitDiv}>
            <article>
                      <h1 className = "hc_param">{this.state.data.headline}</h1>
                      <h2>{this.state.data.subhead}</h2>
              <ul>
                <li>Easily Edit Templates</li>
                <li>Download Unlimited Templates for Free</li>
                <li>Effortlessly Print Templates</li>
                <li>Simple File Sharing Features</li>
              </ul>
              <button class="install" data-cy="cta" onClick = {() => window.triggerInstall()}>
              <p>{this.state.data.mainCTA}</p>
              </button>
            </article>
          </div>
          <div className = {Styles.splitDiv}> <img src="/img/imageCollage.jpg" alt="Freshy Search Templates"/></div>
        </div>
        <footer>
          <ul>
            <li><span></span>{this.state.data.step1}</li>
            <li><span></span>{this.state.data.step2}</li>
            <li><span></span>{this.state.data.step3}</li>
          </ul>
          <p>Tightrope Interactive | <a href="https://freshysearch.com/terms-of-service" target="_blank">Terms</a> | <a href="https://freshysearch.com/privacy-policy" target="_blank">Privacy</a> | <a href="https://freshysearch.com/contact-us" target="_blank">Contact</a> | <a href="https://freshysearch.com/uninstall" target="_blank">Uninstall</a></p>
          <p><a href="https://freshysearch.com/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a> | All trademarks are properties of their respective owners</p>
        </footer>
      </section>
    )
  }
}
export default Trbm
