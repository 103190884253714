import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Nlp from "src/components/modules/nlp"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const data = import("./data/data.json");

export default function prdbest() {
    let module;
    module = null;
    if(isChrome() || isIeEdge()){
       module = <Nlp data={data}></Nlp>
     }
     if(isFirefox()){
       module = <Nlp data={data}></Nlp>
     }
     else if(isSafari()){
      module = <Nlp data={data}></Nlp>;
    }
    
  return(
    <HomepageLayout>
      <Helmet>
        <style type="text/css">{`
        @charset "utf-8";
        html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        	margin: 0;
        	padding: 0;
        	border: 0;
        	font-size: 100%;
        	vertical-align: baseline;
        }
        input, button, submit { border:none; }
        /* HTML5 display-role reset for older browsers */

        article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        	display: block;
        }
        `}
       </style>
       <title>Freshy Search - freshysearch.com</title>
      </Helmet>
      {module}
    </HomepageLayout>
  )
}
